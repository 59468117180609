<template>
  <div class="p-3 pt-0">
    <div class="limit p-3">
      <h1 class="title-header">Suporte</h1>
      <div class="content">
        <div class="content-left">
          <h1>Perguntas frequentes</h1>
          <Patients />
          <Financial />
          <Services />
          <AccountProfile />
        </div>
        <div class="content-right">
          <h1>Entre em contato</h1>
          <Chat :view="securityView" @toggle="changeView" />
          <Email :view="securityView" @toggle="changeView" />
          <Ombudsman :view="securityView" @toggle="changeView" />
        </div>
      </div>

      <div class="container-cards">
        <h1>Últimos artigos</h1>
        <div class="list-cards">
          <div class="card-banner" :style="{ backgroundImage: `url('${bg1}')` }">
            <div class="info">
              <h1>Atendimento remoto - dúvidas</h1>
              <p>Categoria: Profissionais & atendimento</p>
            </div>
            <button>
              Ver artigo
              <img src="../../assets/icons/help/share.png" alt="" />
            </button>
          </div>
          <div class="card-banner" :style="{ backgroundImage: `url('${bg2}')` }">
            <div class="info">
              <h1>Agenda de atendimento</h1>
              <p>Categoria: Profissionais</p>
            </div>
            <button>
              Ver artigo
              <img src="../../assets/icons/help/share.png" alt="" />
            </button>
          </div>
          <div class="card-banner" :style="{ backgroundImage: `url('${bg3}')` }">
            <div class="info">
              <h1>Vacinação, como adicionar?</h1>
              <p>Categoria: Profissionais & pacientes</p>
            </div>
            <button>
              Ver artigo
              <img src="../../assets/icons/help/share.png" alt="" />
            </button>
          </div>
          <div class="card-banner" :style="{ backgroundImage: `url('${bg4}')` }">
            <div class="info">
              <h1>Conecte sua equipe</h1>
              <p>Categoria: Profissionais & equipe</p>
            </div>
            <button>
              Ver artigo
              <img src="../../assets/icons/help/share.png" alt="" />
            </button>
          </div>
        </div>
      </div>
      <footer class="cursor-pointer">
        <p>veja mais artigos relacionados</p>
        <img src="../../assets/icons/help/more.png" alt="" />
      </footer>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Patients from "./Patients.vue";
import Financial from "./Financial.vue";
import Services from "./Services.vue";
import AccountProfile from "./AccountProfile.vue";
import Chat from "./Chat.vue";
import Email from "./Email.vue";
import Ombudsman from "./Ombudsman.vue";
import bg1 from "../../assets/images/help/artigo_1.png";
import bg2 from "../../assets/images/help/artigo_2.png";
import bg3 from "../../assets/images/help/artigo_3.png";
import bg4 from "../../assets/images/help/artigo_4.png";

export default {
  components: {
    Patients,
    Financial,
    Services,
    AccountProfile,
    Chat,
    Email,
    Ombudsman,
  },
  setup() {
    const securityView = ref({
      password: false,
      email: false,
      phone: false,
      address: false,
      devices: false,
    });

    const changeView = (strObj) => {
      if (securityView.value[strObj]) {
        securityView.value = {
          password: false,
          email: false,
          phone: false,
          address: false,
          devices: false,
        };
      } else {
        securityView.value = {
          password: false,
          email: false,
          phone: false,
          address: false,
          devices: false,
        };
        securityView.value[strObj] = true;
      }
    };

    return { securityView, changeView, bg1, bg2, bg3, bg4 };
  },
};
</script>

<style lang="scss" scoped>
.limit {
  max-width: 100%;
}
.btn {
  width: max-content;
  height: 40px;
}
.content {
  display: flex;
  width: 100%;
  gap: 16px;
  margin-top: 36px;
  .content-left {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 50%;
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #bdbdbd;
    }
  }
  .content-right {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 50%;
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #bdbdbd;
    }
  }
  .details-content {
    background: white;
    width: 100%;
    margin-top: -5px;
    padding: 8px 18px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
  }
  h1 {
    color: #4f4f4f;
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }
  .p-14 {
    padding: 14px;
  }
  @media (max-width: 1211px) {
    flex-direction: column;
    .content-left {
      width: 100%;
    }
    .content-right {
      width: 100%;
    }
  }
}
.container-cards {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin-top: 28px;
  .list-cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
  }
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: #bdbdbd;
    margin: 0;
  }
  .card-banner {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 20px 32px;
    background: white;
    border-radius: 8px;
    width: calc(100% / 2 - 10px);
    min-height: 200px;
    h1 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      color: #333333;
    }
    .items{
      display:flex;
      justify-content: space-between;
    }
    .info {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    p{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #FF6A33;
    }
    button {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #2f80ed;
      padding: 10px 20px;
      width: max-content;
      background: none;
      border: 1px solid #2f80ed;
      border-radius: 80px;
      gap: 8px;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 8px 24px rgb(61 71 102 / 8%), 0px 3px 6px rgb(61 71 102 / 6%);
      }
    }
  }
}
footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  color: #ff6a33;
  margin: 100px 0 30px;
}
details > summary {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 18px;
  width: 100%;
  height: 56px;
  background: white;
  box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
  border-radius: 8px;
  border: none;
  cursor: pointer;
  list-style: none;
  font-weight: bold;
}
</style>
